import React from "react"
import Helmet from "react-helmet"

import Layout from "../layouts/en"
import devp from "../../static/devp.png"
import SPHeader from "../components/static-pages-layout/static-page-header"
import Spp from "../components/static-pages-layout/static-page-p"
import SPSection from "../components/static-pages-layout/static-page-section"

const AboutDEVPPage = ({ location }) => {
  const langPaths = { es: "/es/acerca-de-devp" }
  return (
    <Layout location={location} langPaths={langPaths}>
      <Helmet>
        <title>About DEVP</title>
        <meta
          name="description"
          content="DevLand Points are the main token of DevLand"
        />
      </Helmet>
      <SPHeader
        superTitle="About"
        title="DevLand Points (DEVP)"
        imageLight={devp}
        imageDark={devp}
      >
        DevLand Points are the main utility token issued by DevLand to be used
        in the platform. Its supply is limited to 1M and every DEVP that will
        exist is issued already. It is used for governance over DevLand, as
        reward for collaborating on building the platform itself and as balance
        to build projects using the platform.
      </SPHeader>
      <SPSection title="Asset info">
        <Spp>
          Asset code:{" "}
          <a
            href="https://stellar.expert/explorer/public/asset/DEVP-GBJZ2J6JJLWK2PND4ZAWTYKMRAOLDZPOUMZFY6ISIQPOY6HFOL5TIPCR-1"
            target="_blank"
            rel="noreferrer"
          >
            DEVP
          </a>
        </Spp>
        <Spp>
          Issuer account:{" "}
          <a
            href="https://stellar.expert/explorer/public/account/GBJZ2J6JJLWK2PND4ZAWTYKMRAOLDZPOUMZFY6ISIQPOY6HFOL5TIPCR"
            target="_blank"
            rel="noreferrer"
          >
            GBJZ2J6JJLWK2PND4ZAWTYKMRAOLDZPOUMZFY6ISIQPOY6HFOL5TIPCR
          </a>
        </Spp>
        <Spp>Max supply: Fixed, 1.000.000 DEVP</Spp>
      </SPSection>
      <SPSection title="Tokenomics">
        <table>
          <tr>
            <td>Distribution Account</td>
            <td>Usage to be decided by the community</td>
            <td>529.000 DEVP</td>
          </tr>
          <tr>
            <td>DevLand Network Fund</td>
            <td>To build the 1st stage</td>
            <td>50.000 DEVP</td>
          </tr>
          <tr>
            <td>DevLand Academy Fund</td>
            <td>To build the 2nd stage</td>
            <td>50.000 DEVP</td>
          </tr>
          <tr>
            <td>DevLand WorkEngine Fund</td>
            <td>To build the 3rd stage</td>
            <td>100.000 DEVP</td>
          </tr>
          <tr>
            <td>Presale</td>
            <td>To collect funds for the 3 stages of the project</td>
            <td>150.000 DEVP</td>
          </tr>
          <tr>
            <td>Contests & Rewards</td>
            <td>To reward active users for promotion related contests</td>
            <td>20.000 DEVP</td>
          </tr>
          <tr>
            <td>Welcome Bonus</td>
            <td>To reward our first 500 users with 1 DEVP</td>
            <td>500 DEVP</td>
          </tr>
          <tr>
            <td>Liquidity</td>
            <td>To fund liquidity pools</td>
            <td>100.000 DEVP</td>
          </tr>
        </table>
      </SPSection>
      <SPSection title="Presale">
        <Spp>
          150.000 DevLand Points were put on sale for initial offering on
          Stellar DEX in several batches of 20k and a last one of 50k DEVP.
          Starting the first batch at 5 XLM each and ending at 10 XLM.
        </Spp>
        <Spp>
          This offer does not involve the purchase of shares. Users buy the
          right to use the platform features of voting (not legally binding
          results) and building software projects in the platform.
        </Spp>
      </SPSection>
      <SPSection title="Disclaimer">
        <Spp>
          DEVP is not an investment product. There are not any real world assets
          backing its value.
        </Spp>
        <Spp>
          DEVP does not represent shares of DevLand. The results of the voting
          feature are not legally binding, but a private consultation that
          DevLand does to its users before taking the final decision.
        </Spp>
        <Spp>
          Its specific functions are acknowledging and rewarding the members
          that colaborated building the platform and allow them to decide the
          future of it.
        </Spp>
        <Spp>
          We can not really control the DEVP supply, as the issuer account was
          locked to grant its max amount. If for some reason this fact turns
          into a problem in the future, as a DAO we can vote and decide options
          to solve it, like issue a new unlocked token in a 1:1 ratio.
        </Spp>
      </SPSection>
    </Layout>
  )
}
export default AboutDEVPPage
